import Analytics from 'utils/analytics';
import React from 'react';
import SorterHelper from 'utils/sorter';
import UpdaterHelper from 'utils/updater';
import config from 'config.js';
import lokalise from 'utils/lokalise';
import { reactLocalStorage } from 'reactjs-localstorage';
import { v4 as uuidv4 } from 'uuid';

class Updater extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.setupUpdaterHelperCallbacks();
		this.updateCycleIsRunning = false; // Internal app state to init cycle
		this.firstUpdateDone = false; // Internal app state for callbacks
		this.watcher = false;

		// We probably have too much going on here and should refactor into
		// wrapper UpdaterHelper and Geolocation components
		this.setupUpdaterHelperCallbacks = this.setupUpdaterHelperCallbacks.bind(this);
		this.checkQueryParameters = this.checkQueryParameters.bind(this);
		this.checkForLocation = this.checkForLocation.bind(this);
	}

	componentDidMount() {
		this.checkQueryParameters();
		this.checkForLocation();
		//Notify backend that the app is running
		setInterval(() => this.checkAppIsRunning(this.props.params.key, window.location.href), 60000);
	}

	componentDidUpdate(prevProps) {
		// Remember this callback only triggers after the first load

		this.checkForLocation(prevProps);

		if (prevProps?.showTripScreen !== this.props?.showTripScreen) {
			// console.log("Pause updates", prevProps.showTripScreen, this.props.showTripScreen);
			if (this.props.showTripScreen === true) {
				UpdaterHelper.stopCycleDataUpdate();
			} else {
				UpdaterHelper.forceNewUpdate();
			}
		}
	}

	async checkAppIsRunning(key, url) {
		if (reactLocalStorage.get('url') !== url) {
			reactLocalStorage.clear();
		}
		if (!reactLocalStorage.get('trackingUUID')) {
			reactLocalStorage.set('trackingUUID', uuidv4());
			reactLocalStorage.set('url', url);

		}
		const data = await fetch(config.pluginUsageTrackingUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				url: window.location.href,
				plugin_client_guid: reactLocalStorage.get('trackingUUID'),
				api_key: key,
			})
		})
			.then((response) => response.json())
			.catch((error) => {
				console.error('Something went wrong:', error);
			});
		// console.log(data)
	}

	setupUpdaterHelperCallbacks() {
		UpdaterHelper.cardsUpdateStartCallback = () => {
			// console.log("Update Callback Start");
			this.props.updateApp({
				updating: true,
				statusMessage: lokalise.getKey('ts1c'),
			});
		};
		UpdaterHelper.cardsUpdateDoneCallback = (response) => {
			// console.log("Update Callback Done", response);

			//If there is a agency slug to filter by
			const agency = this.props.params.agency ? this.props.params.agency.split(",") : null;
			if (agency) {
				//Filter agencies
				response.data.agencies = response.data.agencies.filter((obj) => {
					return agency.includes(obj?.slug);
				})
				//Entities only get agencyId so prepare to filter by agencyIds that still exist
				const agencyIds = response.data.agencies.map((agency) => {
					return agency.id
				});
				//Filter entities by agency
				response.data.entities = response.data.entities.filter((entity) => {
					return agencyIds.includes(entity.agencyId)
				})
			}

			if (response.data && response.data.entities) {
				// Successful update
				// console.log("Valid JSON", response);
				const {
					cards,
					agencies,
					alerts,
					error,
				} = SorterHelper.getData(response.data);

				// if (this.props.params.sortOrder === 'alphaNumeric') {
				// 	cards.forEach(card => {
				// 		return card?.predictions?.sort(function(a, b) {
				// 			return /[A-Za-z]/.test(a.shortRoute) - /[A-Za-z]/.test(b.shortRoute) || a.shortRoute.charCodeAt(0) - b.shortRoute.charCodeAt(0)
				// 		});
				// 	})
				// }		
				if (error) {
					// Not able to parse API data
					this.props.updateApp({
						statusMessage: lokalise.getKey("ts12y"),
						cards: [],
						debug: JSON.stringify(error),
						statusContactSupport: true,
						loading: false,
						updating: false,
					});
					return;
				}

				if (cards.length > 0) {
					let filteredCards = cards;
					const navButtons= config.modeFilterButtons
					const modeNames = config.modeNames;
					//allowedModes come in initials only so we have to transalte it for CMW
					const allowedModes = this.props.params.allowedModes?.split(',').map(mode => {
						return mode === 'r'
						? 'rail'
						: mode === 'b'
						? 'bus'
						: mode === 'c'
						? 'car'
						: mode === 'm'
						? 'micro'
						: mode === 'o'
						? 'other'
						: null
					}).filter(n => n)

					if (allowedModes) {
						const modes = modeNames.map(mode => {
							if (allowedModes.includes(navButtons[mode].navIconName)) {
								return navButtons[mode].modes;
							}
						}).flat().filter(n => n);
						if (modes.length !== 0) {
							filteredCards = cards.map(card => {
								return modes.includes(card?.agency?.mode) ? card : null;
							}).filter(n => n)
						}
					}
					
					this.props.updateApp({
						screen: response.data.screen,
						address: response.data.address,
						weather: response.data.weather,
						alerts,
						agencies,
						cards: filteredCards,
						action: 'UPDATE_APP',
						// statusMessage: '', // Leaves the last message up
						showAppStatus: false, // Turns off the loading screen
						loading: false, // Turns off appstatus loading
						updating: false, // Turns off specific updating animation
					});
					if (!this.firstUpdateDone) {
						Analytics.recordHit({
							t: 'event',
							ec: 'Updater',
							ea: 'First Update',
							el: `${response.data.entities.length} cards`,
						});
						this.firstUpdateDone = true;
					}
				} else {
					// Update had no cards
					this.props.updateApp({
						statusMessage: lokalise.getKey('ts1r'), // Cant find choices
						cards: [],
						debug: JSON.stringify(response),
						loading: false,
						updating: false,
					});
					if (!this.firstUpdateDone) {
						Analytics.recordHit({
							t: 'event',
							ec: 'Updater',
							ea: 'First Update',
							el: '0 cards',
						});
						this.firstUpdateDone = true;
					}
				}
			} else {
				// API didnt return entities or corrupt JSON syntax, this is rare
				this.props.updateApp({
					statusMessage: lokalise.getKey('ts4a'),
					cards: [],
					debug: JSON.stringify(response),
					loading: false,
					updating: false,
				});
			}
		};
		UpdaterHelper.cardsUpdateErrorCallback = (e) => {
			// console.log('Update Callback Error', e);

			// We receive the literal error response messages here
			const errorMessage = e.toString();

			Analytics.recordHit({
				t: 'event',
				ec: 'Updater',
				ea: 'Error',
				el: errorMessage,
			});


			if (errorMessage.includes('Missing or unauthorized API key')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey('ts4c'), // NO APi key
					debug: errorMessage,
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('Customer does not have API access')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey('ts9x'),
					debug: errorMessage,
					statusContactSupport: true,
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('deploying')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey('ts4b'), // Maintenance
					debug: errorMessage,
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('LocationCode is invalid') || errorMessage.includes('LocationCode is private')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey("ts12z"),
					statusContactSupport: true,
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('LocationCode missing')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey("ts13a"),
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('Server 500')) {
				UpdaterHelper.stopCycleDataUpdate();
				this.props.updateApp({
					statusMessage: lokalise.getKey("ts13b"),
					loading: false,
					updating: false,
				});
			} else if (errorMessage.includes('Error Processing Request')) {
				if (!this.props.params.locationCode) {
					UpdaterHelper.stopCycleDataUpdate();
					this.props.updateApp({
						statusMessage: `${lokalise.getKey("ts13c")} ` + this.props.params.coordinates,
						debug: errorMessage,
						statusContactSupport: true,
						loading: false,
						updating: false,
					});
				}
			} else if (errorMessage.includes('Failed to find stops')) {
				if (!this.props.params.locationCode) {
					UpdaterHelper.stopCycleDataUpdate();
					this.props.updateApp({
						statusMessage: `${lokalise.getKey("ts13c")} ` + this.props.params.coordinates,
						debug: errorMessage,
						statusContactSupport: true,
						loading: false,
						updating: false,
					});
				}
			} else if (UpdaterHelper.failures <= 2) {
				console.log("UpdaterHelper.failures", UpdaterHelper.failures);
				// This is the only flow where Updater.helper will be allowed to retry requests
				this.props.updateApp({
					statusMessage: lokalise.getKey("ts13d"),
					debug: errorMessage,
				});
			} else {
				this.props.updateApp({
					statusMessage: lokalise.getKey("ts13e"),
					debug: errorMessage,
					statusContactSupport: true,
					loading: false,
					updating: false,
				});
			}
		};
	}

	// First step is app checks query params to find out how to configure this app
	checkQueryParameters() {
		const {
			params,
			updateApp
		} = this.props;

		const {
			staging,
			key,
		} = params;

		// Optional Checks First
		if (staging) {
			UpdaterHelper.environment = 'staging';
		}
		if (key) {
			UpdaterHelper.apiKey = key;
			this.setupUpdaterHelperCallbacks();
		} else {
			updateApp({
				statusMessage: lokalise.getKey('ts4c'),
				debug: 'No API Key was supplied in URL',
				loading: false,
			});
		}
	}

	checkForLocation(prevProps) {
		const {
			appLocation,
		} = this.props;

		if (appLocation && (appLocation.locationCode || appLocation.coords)) {
			const previousLocation = prevProps && prevProps.appLocation ? prevProps.appLocation : null;
			this.updateLocation(previousLocation, appLocation);
		}
	}

	/*
	 * @param previousLocation object - This may not exist
	 * @param appLocation object - This is guaranteed or the app will not have arrived here
	 */
	updateLocation(previousLocation, appLocation) {
		// console.log("Updater Location", previousLocation, appLocation);

		// Continually inform Updater of the current location
		// The Update cycle will automatically use these values in API calls
		if (appLocation.locationCode) {
			UpdaterHelper.locationCode = appLocation.locationCode;
			UpdaterHelper.coordinates = null;
		} else if (appLocation.coords) {
			UpdaterHelper.coordinates = appLocation.coords;
			UpdaterHelper.locationCode = null; // IMPORTANT delete the locationcode or it will supersede
		}

		// Now decide Updater lifecycle actions
		if (this.updateCycleIsRunning === false) {
			// Set the app into motion
			this.updateCycleIsRunning = true;
			UpdaterHelper.startCardsUpdates();
		} else {
			// Updater will run continuously in the background
			// Additionally determine if a forced update needs to happen
			// due to Change Location

			// Anywhere
			if (appLocation.coords && previousLocation.coords) {
				let prev = previousLocation.coords;
				let next = appLocation.coords;
				if (prev.latitude !== next.latitude || prev.longitude !== next.longitude) {
					// console.log("Do New Update", prev, next);
					this.props.updateApp({
						cards: []
					});
					UpdaterHelper.forceNewUpdate();
					return;
				}
			}

			// Hub
			if (appLocation.locationCode !== previousLocation.locationCode) {
				this.props.updateApp({
					cards: []
				});
				UpdaterHelper.forceNewUpdate();
			}

			// We wont expose location codes for now
			// if (appLocation.locationCode) {
			//     if (previousLocation.locationCode !== appLocation.locationCode) {
			//         UpdaterHelper.doUpdate();
			//     }
		}
	}

	render() {
		return (
			null
		);
	}
}

Updater.defaultProps = {
	params: {
		staging: false,
		key: null,
	},
};

export default Updater;
