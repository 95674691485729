import DateTimeSelector from 'components/TripScreen/TripSearchScreen/DateTimeSelector';
import { ErrorBoundary } from '@sentry/react';
import ExitButton from 'components/ExitButton';
import Icon from 'components/Icon';
import LoadingAnimation from 'components/common/LoadingAnimation';
import LocationWaypoint from 'components/TripScreen/TripSearchScreen/LocationWaypoint';
import MobilityScoreBadge from 'components/common/MobilityScoreBadge';
import PoweredByAF from 'components/common/PoweredByAF';
import TripOptions from 'components/TripScreen/TripSearchScreen/TripOptions';
import lokalise from 'utils/lokalise';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';

function TripSearchScreen({
    isPermanent,

    getTripPlan,

    startingLocation,
    endingLocation,
    setStartingLocation,
    setEndingLocation,
    startDate,
    startTime,
    setStartDate,
    setStartTime,
    searchingTrips,

    statusMessage,
    errorCount,

    searchAreaLocation,
    tripOptions,
    selectTripOption,

    toggleTripScreen,
    onClickStatusMessage,
}) {

    const [showAdvanced, setAdvanced] = useState(null);


    const [searchParams, setSearchParams] = useSearchParams();
    function formatDateAndTime() {
        const offset = new Date().getTimezoneOffset() * 60000;
        const today = new Date(Date.now() - offset);
        const date = today.toISOString().substring(0, 10);
        const time = today.toISOString().substring(11, 16);
        return [date, time]
    }

    function submitTripPlan() {
        const defaultDateTime = formatDateAndTime();
        let submittedDate = startDate ? startDate : defaultDateTime[0];
        let submittedTime = startTime ? startTime : defaultDateTime[1];

        if (submittedDate) {
            submittedDate = submittedDate.replaceAll("-", "");
        }

        // console.log(submittedDate, submittedTime);
        getTripPlan(submittedDate, submittedTime);
    }

    function switchLocations() {
        const start = startingLocation;
        const startLock = searchParams.get('tpFrom');
        setStartingLocation(endingLocation);
        setEndingLocation(start);
        setSearchParams({
            ...searchParams,
            tpFrom: searchParams.get('tpTo'),
            tpTo: startLock
        })

    }

    function onTripSearch() {
        if (startingLocation && endingLocation) {
            submitTripPlan();
        }
    }

    return (
        <div className="TripSearchScreen">

            <div className="search-area">
                <header className="header">
                    <h1>{lokalise.getKey('ts10j')}</h1>
                    {!isPermanent && <ExitButton showButton={true} tapEvent={() => toggleTripScreen(false)} />}
                </header>

                <div className="locations">
                    <div className="waypoints">
                        <ErrorBoundary fallback={<>{lokalise.getKey("ts12d")}</>}>
                            <LocationWaypoint icon="mapLocation" locked={searchParams.get('tpFrom') === 'locked'} label={lokalise.getKey('ts13j')} placeholder={lokalise.getKey('ts10f')} location={startingLocation} searchAreaLocation={searchAreaLocation} updateLocation={setStartingLocation} />
                            <div className="icons">
                                <Icon category="ui" name='tripLine' className="trip-line" />
                            </div>
                            <LocationWaypoint icon="mapPin" locked={searchParams.get('tpTo') === 'locked'} label={lokalise.getKey('ts13k')} placeholder={lokalise.getKey('ts10g')} location={endingLocation} searchAreaLocation={searchAreaLocation} updateLocation={setEndingLocation} />
                        </ErrorBoundary>
                    </div>
                    <div className="switcher">
                        {
                            searchParams.get('switchLocation') === 'false'
                                ? <></>
                                : <button onClick={() => switchLocations()}><Icon category="ui" name="arrowUpDown" /></button>
                        }
                    </div>
                </div>
                <div className="advanced">
                    <button onClick={() => setAdvanced(!showAdvanced)}>Advanced &rsaquo;</button>
                    <div className="datetime-container" style={{ height: showAdvanced ? 50 : 0 }}>
                        <DateTimeSelector locked={searchParams.get('tpDate') === 'locked'} type='date' startDate={startDate} setStartDate={setStartDate} startTime={startTime} setStartTime={setStartTime} />
                        <DateTimeSelector locked={searchParams.get('tpTime') === 'locked'} type='time' startDate={startDate} setStartDate={setStartDate} startTime={startTime} setStartTime={setStartTime} />
                    </div>
                </div>

                <button className="search-button" onClick={() => onTripSearch()}>{lokalise.getKey(tripOptions ? 'ts13m' : 'ts13i')}</button>
            </div>

            <StatusMessage statusMessage={statusMessage} errorCount={errorCount} onClickStatusMessage={onClickStatusMessage} />

            {!tripOptions && <MobilityScoreBadge startLocation={startingLocation} />}

            <TripOptions tripOptions={tripOptions} selectTripOption={selectTripOption} searchingTrips={searchingTrips} />

            <PoweredByAF />
        </div>
    )
}

function StatusMessage({ statusMessage, errorCount, onClickStatusMessage }) {
    return (
        <div className={`status-message ${statusMessage ? 'visible' : ''}`} onClick={onClickStatusMessage}>
            <div className="text">{statusMessage}</div>
            {errorCount < 2 ? <div className="loading-animation"><LoadingAnimation /></div> : null}
        </div>
    )
}

export default TripSearchScreen;
