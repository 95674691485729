import React, {
	Component
} from 'react';

function VehicleAgencyLogo(props) {
	const {
		card,
		rowData,
	} = props;

	const {
		category,
		agency,
		agencies,
	} = card;

	const {
		agencyId,
	} = rowData;

	// console.log(card, rowData);

	let rowAgency = null;

	if (agencyId && agencies && agencies[agencyId]) {
		// Dockless need to pull it from an agency list
		rowAgency = agencies[agencyId];
	} else if (agency) {
		rowAgency = agency;
	}

	if (rowAgency) {
		let agencyLogo = rowAgency.style.logoSvgOnlight;
		// All content must be https!
		agencyLogo = agencyLogo ? agencyLogo.replace('http://', 'https://') : '';

		return (
			<div className="AgencyLogo">
				<img src={agencyLogo} alt={agency.shortName} />
			</div>
		);
	}

	return null;
}

export default VehicleAgencyLogo;
