import ColorLibrary from "./ColorLibrary";
import Icon from "../Icon";
import styled from "styled-components";

const url = new URL(window.location.href || document.location.search);
const queryParams = new URLSearchParams(url.search);

const Wrapper = styled.div`
    position: relative;

    /* This inherently adapts to the parent div */
    /* The parent div must always set some defined width or height */
    width: 100%;
    height: 100%;

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: auto;
    }

    .mode-icons {
        position: absolute;
        top: 1em;
        width: 100%;
        height: 100%;
    }

    .home-icon {
        position: absolute;
        width: 3em;
        height: 3em;
        left: 50%;
        top: 50%;
        margin-top: -1.5em;
        margin-left: -1.5em;
        svg * {
            stroke: ${props => props.color};
        }
    }

    .circle {
        width: 8em;
        height: 8em;
        /* This class name is assigned by Icon element */
        /* It will change if the icon filename or svg layer position is changed */
        .st1loaderCircle {
            fill: ${props => props.color};
        }
    }

    .is-animated { 
        animation: rotation 2s infinite ease-in-out;
    }
        
`;

export default function LoadingAnimation({ color, stopAnimation }) {

    let styledColor = ColorLibrary.afBlueberry;
    if (queryParams.has('accentColor')) {
        styledColor = '#' + queryParams.get('accentColor');
    } else if (color) {
        styledColor = color; // Direct props passage always overrides
    }

    return (
        <Wrapper color={styledColor}>
            <Icon className="mode-icons" category="ui" name="loaderModeIcons" />
            <Icon className="home-icon" category="ui" name="loaderHomeIcon" />
            <Icon className={`circle ${stopAnimation ? '' : 'is-animated'}`} category="ui" name="loaderCircle" />
        </Wrapper>
    )
}