import config from 'config.js';

// import mockjson from 'mockdata/spothero.json';

export default {

	useMockData: false, // Toggling to true will automatically pipe the above mockjson below

	updateDataRate: config.updateDataRate,

	environment: 'production',

	cardsUpdateStartCallback: () => {},
	cardsUpdateDoneCallback: () => {},
	cardsUpdateErrorCallback: () => {},

	updateCallTimestamp: null, // Tracks to make sure we dont have multiple update timeouts happening

	failures: 0, // Tracks number of failures to decide the update rate
	retries: config.maximumServerErrorRetries, // For all server errors, max retries
	lastErrorMessage: '', // Lets other functions decide how to handle previous failures

	firstLoad: true, // Just for Updater file logic only!

	isUpdating: true,

	// Critical location settings
	coordinates: null,
	locationCode: null,

	getFormattedCoordinates() {
		if (this.coordinates) {
			const latitude = this.coordinates.latitude.toString().substr(0, 14);
			const longitude = this.coordinates.longitude.toString().substr(0, 14);
			return `${latitude},${longitude}`;
		}
		return null;
	},

	// Generates a properly formatted url to access the specified endpointId endpoint
	getEncodedQueryUrl() {
		const coords = this.getFormattedCoordinates();
		let queryUrl;
		queryUrl = `${config[this.environment].autoRouteEndpoint}?`;
		queryUrl += `&key=${this.apiKey}`;
		queryUrl += coords ? `&coordinates=${coords}` : '';
		queryUrl += this.locationCode ? `&locationCode=${this.locationCode}` : '';
		// console.log("getEncodedUrl set to: ", queryUrl);
		return encodeURI(queryUrl);
	},

	// PUBLIC
	// Initializes the Updater cycle with cycleDataUpdate
	async startCardsUpdates() {
		// console.log("Start cards");
		this.isUpdating = true;
		let firstUpdate = await this.doUpdate();
		if (firstUpdate.success) {
			// console.log("First update success");
			this.cycleDataUpdate();
		} else {
			// console.log("First update catch");
			this.updateDataRate = 5000;
			this.cycleDataUpdate();
		}
	},

	// PRIVATE
	// Runs continuously to grab "back-end" data
	// We run this as a setTimeout in order to attenuate the updateDataRate on errors
	cycleDataUpdate() {
		// console.log("** Cycle Data Update ", this.updateDataRate, " Previous?", this.updateCallTimestamp);
		if (this.updateCallTimestamp !== null) {
			return false;
		}
		// console.log("* Proceed...", this.updateCallTimestamp);
		this.updateCallTimestamp = Date.now();
		this.dataUpdateTimeout = setTimeout(() => {
			this.doUpdate().then(this.cycleDataUpdateDone());
		}, this.updateDataRate);
	},

	// PRIVATE
	// This is an important step, it decides whether the update cycle should repeat!
	// You can stop this update anytime in this component by setting this.isUpdating false
	cycleDataUpdateDone() {
		// console.log("* Cycle Data Update Done");
		this.updateCallTimestamp = null;
		this.setDataUpdateRate();
		if (this.isUpdating === true) {
			// console.log("* Repeat?");
			this.cycleDataUpdate();
		}
	},

	// PRIVATE
	// Simply runs a single stand-alone update start to finish
	// Can be triggered at any time in-between the existing cycle
	doUpdate() {
		this.cardsUpdateStartCallback();
		return this.triggerDataUpdate().then((response) => {
			// console.log("** updater success", response);
			if (this.cardsUpdateDoneCallback) {
				this.cardsUpdateDoneCallback(response);
			}
			return {
				success: true,
				response
			};
		}).catch((error) => {
			console.error("** updater error response:", error);
			if (this.cardsUpdateErrorCallback) {
				this.cardsUpdateErrorCallback(error);
			}
			return {
				success: false,
				error
			};
		});
	},

	// PUBLIC
	stopCycleDataUpdate() {
		this.isUpdating = false;
		if (this.dataUpdateTimeout) {
			clearTimeout(this.dataUpdateTimeout);
		}
	},

	// PUBLIC
	// Stop everything and do a completely fresh new call, init cycle and replace content
	// Used for Change Location
	forceNewUpdate() {
		this.stopCycleDataUpdate();
		this.startCardsUpdates();
	},

	/*
	 * PRIVATE
	 * Gets JSON and handles update failures
	 * This is the place to disable the update cycle if needed
	 */
	triggerDataUpdate() {
		// if (this.firstLoad) Performance.start("Updater", "Card Call");
		return new Promise((resolve, reject) => {

			var encodedQueryUrl = this.getEncodedQueryUrl();
			if (!encodedQueryUrl) return reject(new Error(`Updater: No encoded URL produced`));

			this.getJSON(encodedQueryUrl, "GET").then((response) => {
				// console.log("*** triggerDataUpdate: Received Data", response);
				if (this.failures > 0) {
					this.failures = 0;
				}
				// This is a little opinionated as to what is valid JSON
				if (typeof response === "object" && response.data && response.data.entities) {
					return resolve(response);
				} else if (typeof response === "object" && (!response.data || !response.data.entities)) {
					// Valid JSON but missing the properties we want
					return reject(new Error("deploying"));
				} else {
					// This is very unlikely to happen, it would be if Cake sent back nonsense
					return reject(new Error(`Bad response: ${response}`));
				}
			}).catch((e) => {
				// We are just bubbling the errors up, Updater.js decides what UI should do then
				// console.log("*** triggerDataUpdate: Catch: ", e);
				this.failures = this.failures + 1;

				// Handle cancellations
				if (this.failures > this.retries) {
					this.stopCycleDataUpdate();
				}

				this.lastErrorMessage = e;
				return reject(`${this.failures} failures with: ${e}`);
			})

			if (this.firstLoad) {
				// Performance.end("Updater", "Card Call");
				this.firstLoad = false;
			}
		});
	},

	// PRIVATE
	// Adjusts the update card rate
	// Do note that maximumServerErrorRetries limits the number of failure calls we'll try anyway
	setDataUpdateRate() {

		if (typeof this.lastErrorMessage === "string" && this.lastErrorMessage.indexOf("deploying") !== -1) {
			this.updateDataRate = 20000;
			return;
		}

		if (this.failures === 0) {
			this.updateDataRate = config.updateDataRate;
		} else if (this.failures === 1) {
			this.updateDataRate = 5000;
		} else if (this.failures === 2) {
			this.updateDataRate = 10000;
		} else if (this.failures >= 4 && this.failures <= 6) {
			this.updateDataRate = 15000;
		}
	},


	/*
	 * ======================================================================
	 * All functions below this line are purely functional to process and validate the server calls and JSON response
	 * Methods here bubble up any responses to methods above this line
	 * Any decisions on start, stop, altering the update cycle should occur above
	 * ======================================================================
	 */

	/*
	 * This is a wrapper function that institutes a timeout to the fetch call to effectively cancel it
	 */
	async getJSON(encodedQueryUrl = "", method = "POST", data = {}, timeout = config.updateDataTimeout) {

		return new Promise((resolve, reject) => {

			var controller = null;
			var signal = null;

			if (window.AbortController) {
				controller = new AbortController();
				signal = controller.signal;
			}

			let fetchTimeout = setTimeout(() => {
				// Allow for abortion of call if timed out
				if (controller) {
					console.log("Updater: Call timed out");
					controller.abort();
				}
				reject(new Error(`Updater: Request timed out w/url ${encodedQueryUrl}`));
			}, timeout);

			// Send back mock data if flag set
			if (this.useMockData) {
				clearTimeout(fetchTimeout);
				resolve(mockjson);
				return; // Exit!
			}

			// Production call to API
			this.fetchJSON(encodedQueryUrl, method, data, signal).then((response) => {
				clearTimeout(fetchTimeout);
				resolve(response);
			}).catch((e) => {
				clearTimeout(fetchTimeout);
				reject(e);
			});
		});
	},

	/*
	 * This is just an extension of getJSON
	 * Only concerned about logic issues, bad responses, data, parsing, etc
	 * And returning the proper promise response
	 * @param encodedQueryUrl string - URL to perform fetch
	 * @param method string - Usually POST
	 * @param data object - Converted into string for POST Body, usually empty
	 * @param signal abort object - Allows cancelling of fetch
	 */
	fetchJSON(encodedQueryUrl, method, data, signal) {
		const body = method === "POST" ? JSON.stringify({
			data
		}) : null;
		return new Promise((resolve, reject) => {
			fetch(encodedQueryUrl, {
				method,
				body,
				signal,
				cache: 'no-store', // Big bust for now, change to no-cache later
				mode: 'cors',
				redirect: 'follow',
				credentials: 'same-origin',
				// headers: {
				// 'Access-Control-Allow-Origin': '*',
				// 'Accept': 'application/json',
				// 'Content-Type': 'application/json'
				// }
			}).then((response) => {
				// console.log("Fetch response: ", response, encodedQueryUrl);
				let status = response.status;
				if (status === 200) {
					response.json().then((json) => {
						// console.log("OK", json);
						resolve(json);
					}).catch((error) => {
						// console.log("JSON Parse Problem: ", error);
						reject(`Updater: JSON parse error: ${error}`);
					});
				} else if (status === 503) {
					// We presume this to be TS deploy
					reject(`Server deploying ${response.server}`);
				} else {
					// All other status codes are marked bad requests and allowed retries
					response.json().then((json) => {
						if (json) {
							let errorMessage = `Updater: Error ${json.code} Bad request: ${json.message}`;
							// console.log("fetchUpdate Error", errorMessage);
							reject(errorMessage);
						} else {
							// console.log("Server sent back no data");
							reject(`Updater: Server sent back no data`);
						}
					}).catch((e) => {
						reject(`Updater: Server sent bad data / ${e}`);
					});
				}
			}).catch((error) => {
				// Fetch can throw under these conditions:
				// - No or very weak network connection
				// - Cake did not respond to the request
				// - Some JS Exception, like a variable assignment
				let errorMessage = `Updater Catch: Fetch error: ${error} w/url ${encodedQueryUrl}`;
				console.log("Updater Catch: ", errorMessage, error);
				reject(errorMessage);
				// @TODO Add recovery steps like Check network connection
			});
		});
	}
}
