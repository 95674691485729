import { useEffect } from 'react';

function setStyle(varName, color) {
    document.documentElement.style.setProperty('--' + varName, '#' + color);
}

function setPercentage(varName, percent) {
    document.documentElement.style.setProperty('--' + varName, percent + '%');
}

// @param params - the URL params giving us this info
// @param screenStyle - in the future we may try to link Hub or some style obj here
function ThemeStyle({ params, screenStyle }) {
    const {
        mainText,
        mainBg,
        cardText,
        cardBg,
        menuText,
        menuBg,
        navText,
        navBg,
        borderColor,
        accentColor,
        zoom
    } = params || {};

    useEffect(() => {

        // Reference the light.scss file for root overrides
        // We use root overrides which will directly target changes where needed versus
        // using imperfect css to override with !important 

        // We only need to do this once as ?parameter values are guaranteed to be available on first load

        // Note that some styles apply initially which can later be overriden
        // by more specific ones (mainText/mainBg are defaults for many for example)

        if (mainText) {
            setStyle('mainText', mainText);
            setStyle('secondaryText', mainText);
            setStyle('menuText', mainText);
            setStyle('inputText', mainText);
        }

        if (mainBg) {
            setStyle('appBackground', mainBg);
            setStyle('menuBackground', mainBg);
        }

        if (cardText) {
            setStyle('cardText', cardText);
            setStyle('unitText', cardText);
            setStyle('uiIconColor', cardText);
            setStyle('modeIconColor', cardText);
            setStyle('secondaryText', cardText);
        }

        if (cardBg) {
            setStyle('cardBackground', cardBg);
        }

        if (menuText) {
            setStyle('appHeaderText', menuText);
        }

        if (menuBg) {
            setStyle('appHeaderBackground', menuBg);
        }

        if (navText) {
            setStyle('buttonText', navText);

            setStyle('navButton', navText);
            setStyle('navButtonActiveText', navText);
            setStyle('navButtonActiveBackground', navText);

            setStyle('uiActive', navText);
        }

        if (navBg) {

            setStyle('buttonBackground', navBg);

            // setStyle('navButtonActive', navBg);
            setStyle('navBackground', navBg);
        }

        if (borderColor) {
            setStyle('borderColor', borderColor);
        }

        if (accentColor) {
            setStyle('navButtonActiveBackground', accentColor);
            setStyle('navButtonAltBg', accentColor);
            setStyle('exitButtonBackground', accentColor);
            setStyle('icons', accentColor);
        }

        if (zoom) {
            setPercentage('zoom', zoom);
        }

    }, []);

    return null;
}

export default ThemeStyle
